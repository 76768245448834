import { Injectable } from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {BaseErrorHandleInterface} from "./base-error.handle.interface";
import {ErrorLocalHandle} from "./error-local.handle";
import {Error400Handle} from "./error-400.handle";
import {Error401Handle} from "./error-401.handle";
import {Error403Handle} from "./error-403.handle";
import {Error404Handle} from "./error-404.handle";
import {Error422Handle} from "./error-422.handle";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  myDictionary: { [index: number]: BaseErrorHandleInterface; } = {};
  errorCargarInformacion: ErrorLocalHandle;
  constructor(){
    this.myDictionary[ErrorLocalHandle.CODIGO_HTTP_ERROR] = new ErrorLocalHandle();
    this.myDictionary[Error400Handle.CODIGO_HTTP_ERROR] = new Error400Handle();
    this.myDictionary[Error401Handle.CODIGO_HTTP_ERROR] = new Error401Handle();
    this.myDictionary[Error403Handle.CODIGO_HTTP_ERROR] = new Error403Handle();
    this.myDictionary[Error404Handle.CODIGO_HTTP_ERROR] = new Error404Handle();
    this.myDictionary[Error422Handle.CODIGO_HTTP_ERROR] = new Error422Handle();
    this.errorCargarInformacion = new ErrorLocalHandle();
  }

  /**
   * A partir del error pasado como objeto, lo maneja a partir de su código de estado
   */
  manejar(httpError?: HttpErrorResponse ): string {
    const codigoError = (! httpError || ! httpError.status || ! this.myDictionary[httpError.status] )
      ? ErrorLocalHandle.CODIGO_HTTP_ERROR
      : httpError.status;
    return this.myDictionary[codigoError].manejar(httpError);
  }
}
