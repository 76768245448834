import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {IuController} from "../../sistema/controladores/iu/iu.controller";

@Injectable({
  providedIn: 'root'
})
export class AutenticadoGuard implements CanActivate {

  constructor( private _authService: AuthService, protected _iuController: IuController) {  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const autenticado = this._authService.estaAutenticado();
    if(autenticado) {
      return true;
    }
    else {
      this._iuController.mostrarMensajeOk('Alerta', 'No tienes permiso para acceder a esta sección');

      this._iuController.navegar("login");
    }
    return false;
  }

}
