import {BaseErrorHandleInterface} from "./base-error.handle.interface";
import {HttpErrorResponse} from "@angular/common/http";

export class Error400Handle implements BaseErrorHandleInterface{

  static readonly CODIGO_HTTP_ERROR = 400;

  manejar(object?: HttpErrorResponse): string {
    return "Petición o Solicitud Incorrecta.";
  }

}
