import {BaseErrorHandleInterface} from "./base-error.handle.interface";

export class ErrorLocalHandle implements BaseErrorHandleInterface{

  static readonly CODIGO_HTTP_ERROR = -1;

  manejar(): string {
    return 'Se ha producido un error al intentar cargar la información';
  }


}
