import { Injectable } from '@angular/core';
import {UsuarioInterface} from "../modelo/usuario.interface";
import {ClienteRestService} from "../../sistema/servicios/clientes/cliente-rest.service";
import {ClienteDbService} from "../../sistema/servicios/clientes/cliente-db.service";
import {environment} from "../../../../environments/environment";
import {Subject} from "rxjs";
import {MensajeConfirmacionResultadoInterface} from "../../sistema/modelo/mensaje-confirmacion-resultado.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static readonly KEY_TOKEN = 'token';


  private _usuarioModificado = new Subject<boolean>();

  get usuarioModificado$() {
    return this._usuarioModificado.asObservable();
  }

  constructor(private _clienteRest: ClienteRestService,
              private _clienteDb: ClienteDbService) { }

  /**
   * Devuelve el token obtenido del servicio Rest tras enviarle los datos de autenticación
   */
  async login(usuario: UsuarioInterface): Promise<string>{
    const request = await this._clienteRest.peticionPOST(
      environment.url_base + environment.url_autenticar,
      usuario);
    localStorage.setItem("Usuario", usuario.email);
    const token = request.token;
    this._clienteDb.almacenarObjeto(AuthService.KEY_TOKEN, token);
    this._usuarioModificado.next(true);
    return token;
  }

  /**
   * Hace la petición al servidor para que se reestablezca la contraseña y devuelve la respuesta del servidor.
   */
  async recuperar(usuario: UsuarioInterface): Promise<boolean> {
    return await this._clienteRest.peticionPOST(
      environment.url_base + environment.url_recordar_password,
      usuario);
  }

  /**
   * Elimina toda la información almacenada en local
   */
  logout(): void {
    this._clienteDb.eliminarTodo();
    this._usuarioModificado.next(true);
  }

  /**
   * Indica si el usuario esta autenticado en el sistema a traves del token,
   * es posible que el token haya caducado y se debe de hacer de nuevo login.
   */
  obtenerToken(): string{
    return this._clienteDb.obtenerObjetoAlmacenado(AuthService.KEY_TOKEN);
  }

  /**
   * Indica si el usuario esta autenticado en el sistema a traves del token,
   * es posible que el token haya caducado y se debe de hacer de nuevo login.
   */
  estaAutenticado(): boolean{
    return this.obtenerToken() !== null;
  }

}
