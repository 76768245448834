import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteRestService {

  TIME_OUT = 10000;
  PREFIJO_HEADER_TOKEN = 'Bearer ';

  constructor(private http: HttpClient) {}

  /**
   * Realiza la petición Get con el token de autenticación a la URL indicada
   */
  public peticionAutenticadaGET(url: string, token: string): Promise<any> {
    const parametros = new HttpParams();
    return this.http.get(url, this.obtenerOpcionesAutenticadasParametros(parametros, token))
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición Get con el token de autenticación a la URL indicada con los parametros aportados
   */
  public peticionAutenticadaParametrosGET(
    url: string,
    parametros: any,
    token: string): Promise<any> {
    return this.http.get(url, this.obtenerOpcionesAutenticadasParametros(parametros, token))
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición Get a la URL indicada
   */
  public peticionGET(url: string): Promise<any> {
    return this.http.get(url)
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición Get a la URL indicada con parametros
   */
  public peticionConParametrosGET(url: string, parametros: HttpParams): Promise<any> {
    return this.http.get(url, {params: parametros})
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición POST con el token de autenticación a la URL indicada con los parametros aportados
   */
  public peticionAutenticadaPOST(url: string, parametros: any,  token: string): Promise<any> {
    return this.http.post(url, parametros, this.obtenerOpcionesAutenticadas(token))
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición POST a la URL indicada con los parametros aportados
   */
  public peticionPOST(url: string, parametros: any): Promise<any> {

    return this.http.post(url, parametros)
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición PUT con el token de autenticación a la URL indicada con los parametros aportados
   */
  public peticionAutenticadaPUT(url: string, parametros: any, token: string): Promise<any> {
    if (parametros.direccion && parametros.direccion.codigoPostal) {
        parametros.direccion.codigoPostal = Number(parametros.direccion.codigoPostal);
    }
    return this.http.put(url, parametros, this.obtenerOpcionesAutenticadas(token))
        .pipe(timeout(this.TIME_OUT))
        .toPromise(); 
  }

  /**
   * Realiza la petición PUT a la URL indicada con los parametros aportados
   */
  public peticionPUT(url: string, parametros: any): Promise<any> {

    return this.http.put(url, parametros)
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Realiza la petición Delete con el token de autenticación a la URL indicada
   */
  public peticionAutenticadaDelete(url: string, token: string): Promise<any> {
    const parametros = new HttpParams();
    return this.http.delete(url, this.obtenerOpcionesAutenticadasParametros(parametros, token))
      .pipe(timeout(this.TIME_OUT))
      .toPromise();
  }

  /**
   * Genera las cabeceras para hacer las llamadas al servicio REST
   */
  private obtenerOpcionesAutenticadas(token: string): any {

    return {
      headers: new HttpHeaders().set('Authorization', this.PREFIJO_HEADER_TOKEN  +  token)
    };
  }

  /**
   * Genera las cabeceras para hacer las llamadas al servicio REST
   */
  private obtenerOpcionesAutenticadasParametros(parametros: any, token: string): any {

    return {
      headers: new HttpHeaders().set('Authorization', this.PREFIJO_HEADER_TOKEN +  token),
      params: parametros
    };
  }
}
