import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClienteDbService {


  /**
   * Almacena el objeto introducido en formato texto, en la base de datos local
   */
  public almacenarObjeto(nombreObjeto: string, objeto: any) {
      localStorage.setItem(this.cifrar(nombreObjeto), this.cifrar(JSON.stringify(objeto)));
  }

  /**
   * Almacena el objeto introducido en formato texto, en la base de datos local
   */
  public almacenarObjetoPlano(nombreObjeto: string, objeto: any) {
      localStorage.setItem(this.cifrar(nombreObjeto), this.cifrar(objeto));
  }

  /**
   * Obtiene el objeto almacenado en la base de datos local
   */
  public obtenerObjetoAlmacenado(nombreObjeto: string) {
      const objeto = localStorage.getItem(this.cifrar(nombreObjeto));
      return objeto == null ? null : JSON.parse(this.descifrar(objeto));
  }

  /**
   * Obtiene el objeto en texto plano almacenado en la base de datos local
   */
  public obtenerObjetoPlanoAlmacenado(nombreObjeto: string) {
      return this.descifrar(localStorage.getItem(this.cifrar(nombreObjeto)));

  }

  /**
   * Elimina el objecto almacenado con la clave introducida en la base de datos local
   */
  public eliminarObjetoAlmacenado(nombreObjeto: string) {
    return localStorage.removeItem(this.cifrar(nombreObjeto));
  }

  /**
   * Elimina todos los datos almacenados en la base de datos local
   */
  public eliminarTodo() {
    return localStorage.clear();
  }

  /**
   * cifra el texto pasado por parametro
   */
  private cifrar(texto: string): string{
    return environment.production ? btoa(texto) : texto;
  }

  /**
   * descifra el texto pasado por parametro
   */
  private descifrar(texto: string): string{
    return environment.production ? atob(texto) : texto;
  }
}
