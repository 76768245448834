import {BaseErrorHandleInterface} from "./base-error.handle.interface";
import {HttpErrorResponse} from "@angular/common/http";

export class Error422Handle implements BaseErrorHandleInterface{

  static readonly CODIGO_HTTP_ERROR = 422;

  manejar(object?: HttpErrorResponse): string {
    return 'No se ha podido procesar su solicitud correctamente.';
  }

}
