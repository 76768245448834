import {BaseErrorHandleInterface} from "./base-error.handle.interface";
import {HttpErrorResponse} from "@angular/common/http";

export class Error401Handle implements BaseErrorHandleInterface{

  static readonly CODIGO_HTTP_ERROR = 401;

  manejar(object?: HttpErrorResponse): string {
    return 'No tiene permisos para acceder';
  }

}
