import {BaseErrorHandleInterface} from "./base-error.handle.interface";
import {HttpErrorResponse} from "@angular/common/http";

export class Error404Handle implements BaseErrorHandleInterface{

  static readonly CODIGO_HTTP_ERROR = 404;

  manejar(object?: HttpErrorResponse): string {
    return 'No se ha encontrado el recurso solicitado';
  }

}
