import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {AlertController, LoadingController} from "@ionic/angular";
import {ErrorService} from "../../servicios/errores/error.service";
import {HttpErrorResponse} from "@angular/common/http";
import {Subject} from "rxjs";
import {MensajeConfirmacionResultadoInterface} from "../../modelo/mensaje-confirmacion-resultado.interface";

@Injectable({
  providedIn: 'root'
})
export class IuController{

  private _mensajeConfirmcacion = new Subject<MensajeConfirmacionResultadoInterface>();

  get mensajeConfirmcacionObservable$() {
    return this._mensajeConfirmcacion.asObservable();
  }


  constructor(
    private _router: Router,
    private _alertController: AlertController,
    private _loadingCtrl: LoadingController,
    private _errorService: ErrorService,
  ) { }

  public navegar(ruta: string){
    this._router.navigate([ruta]);
  }

  public abrirPaginaNuevaPestana(url: string){
    window.open(url, '_blank');
  }

  public generarUuid(){
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });

  }

  public getUrlActual(){
    return this._router.url;
  }

  public irAtras(){
    window.history.back();
  }

  /**
   * Muestra un mensaje con el boton de OK
   */
  public async mostrarMensajeOk(cabecera: string, mensaje: string): Promise<void>{
    const alert = await this._alertController.create({
      // cssClass: 'my-custom-class',
      header: cabecera,
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();
  }

  /**
   * Muestra un mensaje con el boton de OK y cancelar
   */
  async mostrarMensajeConfirmacion(cabecera: string, mensaje: string, uuid: string): Promise<void> {
    const alert = await this._alertController.create({
      //cssClass: 'my-custom-class',
      header: cabecera,
      message: mensaje,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancelar-button',
          handler: () => {
            this._mensajeConfirmcacion.next({uuid: uuid, respuesta: false})
          }
        }, {
          text: 'Aceptar',
          id: 'confirmado-button',
          handler: () => {
            this._mensajeConfirmcacion.next({uuid: uuid, respuesta: true})
          }
        }
      ]
    });

    return await alert.present();
  }

  /**
   * Muestra un mensaje de cargando y cuando termina la ejecuación asyncrona lo oculta
   */
  public async cargarDatosConLoader<T>(promesa: Promise<T>, mostrarMensajeError : boolean = true): Promise<T>{
    let loader: any;
    loader = await this._loadingCtrl.create({
      message: 'Cargando...'
    });
    loader.present();

    return await promesa
      .catch(ex => {
        if(mostrarMensajeError) {
          const mensaje = (ex instanceof HttpErrorResponse) ? this._errorService.manejar(ex) : this._errorService.manejar();
          this.mostrarMensajeOk("Error", mensaje);
        }
        throw ex;
      })
      .finally(() => {
        loader.dismiss();
      });

  }
}
